import React from "react";
import globalStyles from "../styles/index.module.scss";
import styles from "../components/hero.module.scss";
import VinomofoLogo from "../components/vinomofo-logo";
import popupLogo from "../../static/images/popup-sale-logo.png";
import { Helmet } from "react-helmet";

export default () => {
  return (
    <div className={globalStyles.container}>
      <Helmet
        defaultTitle="POP-UP Sale event | Vinomofo New Zealand"
        titleTemplate="%s | Vinomofo New Zealand"
      >
        <link
          rel="stylesheet"
          href="https://fonts.vinomofo.io/css?family=GT+Walsheim:500,700&family=Graphik:400,400italic,500,500italic"
        />
      </Helmet>
      <div className={styles.popupHero}>
        <div className={styles.popupHero__inner}>
          <div className={styles.popupHero__logo}>
            <VinomofoLogo />
          </div>
          <div className={styles.popupHero__saleLogo}>
            <img src={popupLogo} alt="POP-UP Sale event" />
          </div>
          <div className={styles.popupHero__content}>
            <h1 className={styles.popupHero__heading}>
              Thanks for joining!
            </h1>
            <div className={styles.popupHero__join}>
              <p>
                Stay tuned, the pop up event will begin when the container lands.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
